import React from 'react';

import {
    container,
    button,
    noPromotions,
    details,
    promotionItem,
} from './cart-summary.module.scss';
import { useCart } from '../../hooks/use-cart';
import useTranslations from '../../hooks/use-translations';

import DataHeading from '../molecules/data-heading';
import PromotionMessagesList from '../molecules/promotion-messages-list';
import OrderDetails from '../molecules/order-details';
import SummaryDisclaimer, { ISummaryDisclaimerProps } from '../molecules/summary-disclaimer';
import Button from '../atoms/button';

interface ICartSummaryProps {
    className?: string;
    buttonLink?: string;
    summaryDisclaimerProps?: Pick<ISummaryDisclaimerProps, 'firstInfo' | 'secondInfo'>;
    rentMode?: boolean;
    subscriptionMode?: boolean;
}

const CartSummary: React.FC<ICartSummaryProps> = ({
    className = '',
    buttonLink,
    summaryDisclaimerProps,
    rentMode = false,
    subscriptionMode,
}) => {
    const t = useTranslations('CartSummary');
    const cartState = useCart();
    const promotions = cartState.data?.promotions.filter(
        (promotion) => promotion.messages.length > 0
    );

    const noPromotionsClass = !promotions?.length ? noPromotions : '';

    return (
        <div className={`${container} ${className} ${noPromotionsClass}`}>
            <DataHeading firstColumn={t.title} />
            {!subscriptionMode && !rentMode && promotions && promotions.length > 0 && (
                <PromotionMessagesList promotions={promotions} itemClassName={promotionItem} />
            )}
            <OrderDetails
                className={details}
                itemsRegularPrice={cartState.data?.regularPrice}
                itemsFinalPrice={cartState.data?.finalPrice}
                deliveryRegularPrice={cartState.data?.delivery?.regularPrice}
                deliveryFinalPrice={cartState.data?.delivery?.finalPrice}
                deliveryStepIsComplete={false}
                rentMode={rentMode}
                subscriptionMode={subscriptionMode}
                context="cart"
            />
            <SummaryDisclaimer {...summaryDisclaimerProps}>
                {buttonLink && (
                    <Button to={buttonLink} as="link" className={button}>
                        {t.button}
                    </Button>
                )}
            </SummaryDisclaimer>
        </div>
    );
};

export default CartSummary;
