import SectionHeader from '../components/sections/header';
import SectionCart from '../components/sections/cart';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISection, ISectionConfig } from '../models/section.model';
import { getNodes } from '../utils/get-nodes';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';

interface IIndexProps {
    readonly data: {
        page: IPage;
        allPage: IQueryAllResult<Pick<IPage, 'type' | 'pathname'>>;
    };
}

export type TOrderAvailablePaths = Record<
    'cart' | 'order-login' | 'order' | 'register' | 'password-recovery',
    string
>;

export type TOrderSection = Omit<ISection, 'settings'> & {
    settings: ISectionConfig & Record<'availablePaths', TOrderAvailablePaths>;
};

const Order: React.FC<IIndexProps> = ({ data }) => {
    const { page, allPage } = data;

    const sections = getSectionsWithRepeatIndex(page.sections);

    const availablePaths = Object.fromEntries(
        getNodes(allPage).map(({ type, pathname }) => [type, pathname])
    );

    return (
        <MainLayout>
            {sections.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={{
                            ...section,
                            settings: { ...section.settings, availablePaths },
                        }}
                        TitleTag={section.isMain ? 'h1' : 'h2'}
                    />
                );
            })}
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
        allPage(
            filter: {
                locale: { eq: $locale }
                type: { in: ["cart", "order-login", "order", "register", "password-recovery"] }
            }
        ) {
            edges {
                node {
                    type
                    pathname
                }
            }
        }
    }
`;

export default Order;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'cart': SectionCart,
    'footer': SectionFooter,
};